import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7rem 0 2rem;
  background-repeat: no-repeat;

  ${process.env.REACT_APP_LANDING_BACKGROUND_HEIGHT &&
  `height: ${process.env.REACT_APP_LANDING_BACKGROUND_HEIGHT}; padding: 0;`};

  @media only screen and (min-width: ${(props) => props.theme.screenWidthSm}) {
    padding: 7rem 0 ${process.env.REACT_APP_LANDING_LOGO_SIZE ? "3rem" : "6rem"};

    ${process.env.REACT_APP_LANDING_BACKGROUND_HEIGHT &&
    `height: ${process.env.REACT_APP_LANDING_BACKGROUND_HEIGHT}; padding: 0;`};
  }

  @media only screen and (max-width: ${(props) => props.theme.screenWidthXs}) {
    height: 440px; 
    padding: 0;
  }
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 1;
  height: ${process.env.REACT_APP_LANDING_LOGO_SIZE || "90px"};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    height: ${process.env.REACT_APP_LANDING_LOGO_SIZE || "100px"};
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(p) =>
    p.theme.colorPrimaryGradient ? null : rgba(p.theme.colorPrimary, 0.75)};
`;

const LandingTopbar = (props) => (
  <StyledWrapper
    style={{
      backgroundSize: process.env.REACT_APP_LANDING_BACKGROUND_SIZE || "cover",
      backgroundPosition: "center",
      backgroundImage: process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE
        ? null
        : `url(${createImageCdnLink(
            process.env.REACT_APP_LANDING_BACKGROUND
          )})`,
    }}
  >
    {process.env.REACT_APP_LANDING_BACKGROUND_HAS_OVERLAY !== "false" && (
      <StyledOverlay />
    )}
    <StyledImage
      src={createImageCdnLink(
        process.env.REACT_APP_LANDING_LOGO || process.env.REACT_APP_MAIN_LOGO
      )}
    />
  </StyledWrapper>
);

export default LandingTopbar;
