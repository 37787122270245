import React, { useEffect } from "react";
import axios from "axios";

import { Route, useLocation, useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { darken } from "polished";
import parse from "url-parse";
import { createImageCdnLink } from "utils";
import { AffiliatePopup } from "components/shared";
import Topbar from "./layout/topbarNew/Topbar";
import Bottombar from "./layout/bottombar/Bottombar";
import BottombarIcon from "./layout/bottombar/BottombarIcon";
import Bottombar2021 from "./layout/bottombar/Bottombar2021";
import Content from "./layout/content/Content";
import Drawer from "./layout/drawer/Drawer";
import Toolbar from "components/layout/toolbar/Toolbar";
import AffiliateLogo from "components/layout/affiliateLogo/AffiliateLogo";
import { SportsRadarBanner } from "components/shared";

import { Overlay } from "fiver-react-ui-components";
import { setToolbar } from "components/layout/toolbar/actions";
import { getPage } from "./layout/drawer/actions";

import { LegalFooter, Low6Footer } from "fiver-react-ui-components";

const StyledFooterLink = styled.a`
  display: inline-block;
  width: 100%;
  margin: 0 0 3rem;
  color: ${(p) =>
    process.env.REACT_APP_HAS_DARK_BACKGROUND === "true"
      ? p.theme.colorPrimaryText
      : p.theme.colorLabel};
  font-size: 0.6rem;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

const ToastStylesForBottomBarPages = createGlobalStyle`
  body .Toastify__toast-container--bottom-center {
    bottom: 0;
    z-index: 6;
  }
`;

const ToastStylesForBottomBarWithIcon = createGlobalStyle`
  body .Toastify__toast-container--bottom-center {
    bottom: 0;
    z-index: 6;
  }

  body .Toastify__toast {
    margin-bottom: 0;
    min-height: 98px;
    font-size: 1.25rem;
  }
`;

const BodyBackgroundColorPrimary = createGlobalStyle`
body {
  background: ${(props) =>
    props.hasPrimaryBackground
      ? props.theme.colorPrimaryGradient || props.theme.colorPrimary
      : process.env.REACT_APP_BACKGROUND_IMAGE
        ? `${darken(0.2, props.theme.colorPrimary)} url(${createImageCdnLink(
          process.env.REACT_APP_BACKGROUND_IMAGE
        )})`
        : props.theme.colorLightGrey};


  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    background: ${(props) => props.theme.colorBackground};
  }
}
`;

const heightToAppear = 1;

const AppRoute = ({
  path,
  exact,
  isAuthRequired,
  isLandingPage,
  isLeaderboard,
  hideLow6Footer,
  hideLegalFooter,
  needsPaddingTop,
  component,
  noPadding,
  hasAffiliateLogo,
  hasAffiliatePopup,
  hasPrimaryBackground,
  hasToolbar,
  topbarTitleLogo,
  topbarTitle,
  topbarBackButton,
  topbarBackButtonText,
  topbarBurgerMenu,
  buttonWidth,
  bottombar,
  getPage,
  style,
  machData,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const url = parse(location?.search, true);
  const isFromSignupPage = url.query.signup === "true";

  const xtremePushId = url.query.xtreme_push_id;
  // console.log(device_id, 'IDS');
  const { hasReadBetbarHelp } = useSelector((s) => s.app);
  const { isBetsOpen, activePickemList, activePickem, activeBet } = useSelector(
    (state) => state.toolbar
  );

  const handleOverlayClicked = () => {
    dispatch(
      setToolbar({
        isBetsOpen: false,
      })
    );
  };

  const shouldShowOverlayForBetbar =
    process.env.REACT_APP_HIDE_BETBAR_HELP !== "true" &&
    activePickemList?.length > 1 &&
    !hasReadBetbarHelp;

  useEffect(() => {
    (async () => {
      if (isAuthRequired) {
        try {
          const me = await axios.get("/me");
        } catch ({ response }) {
          history.push("/");
        }
      }
    })();
  }, []);

  return (
    <Route path={path} exact={exact}>
      <BodyBackgroundColorPrimary hasPrimaryBackground={hasPrimaryBackground} />

      {process.env.REACT_APP_IS_TOPBAR_HIDDEN !== "true" &&
        (topbarTitle || topbarTitleLogo)
        ? React.createElement(Topbar, {
          topbarTitle,
          topbarBackButton: isFromSignupPage ? false : topbarBackButton,
          topbarBackButtonText,
          topbarBurgerMenu,
          topbarTitleLogo,
          buttonWidth,
        })
        : null}
      {hasToolbar && (
        <>
          <Overlay
            zIndex={shouldShowOverlayForBetbar ? 6 : 3}
            onClick={handleOverlayClicked}
            isVisible={isBetsOpen || shouldShowOverlayForBetbar}
          />
          <Toolbar isLeaderboard={isLeaderboard} />
        </>
      )}
      {bottombar ? (
        process.env.REACT_APP_HAS_NEW_BOTTOMBAR === "true" ? (
          <Bottombar2021 />
        ) : (
          <>
            {process.env.REACT_APP_NAV_ICON && (
              <>
                <ToastStylesForBottomBarWithIcon />
                <BottombarIcon />
              </>
            )}
            {!process.env.REACT_APP_NAV_ICON && (
              <>
                <ToastStylesForBottomBarPages />
                <Bottombar />
              </>
            )}
          </>
        )
      ) : null}
      <Content
        needsPaddingTop={needsPaddingTop}
        needsExtraPaddingTop={
          activeBet && activePickem?.status !== "APPROVED" && isLeaderboard
        }
        noPadding={noPadding}
        hasPrimaryBackground={hasPrimaryBackground}
        hasBetbar={hasToolbar}
        style={style}
      >
        {hasAffiliateLogo && process.env.REACT_APP_AFFILIATE_LOGO && (
          <AffiliateLogo />
        )}
        {React.createElement(component)}

        {!hideLegalFooter &&
          React.createElement(LegalFooter, {
            isDark:
              isLandingPage ||
              process.env.REACT_APP_BACKGROUND_IMAGE ||
              process.env.REACT_APP_HAS_DARK_BACKGROUND === "true",
            getPage,
            translations: {
              gamblingSupport: "Gambling Support",
              contactSupport: "Contact Support",
              responsibleGambling: "Responsible Gambling",
              termsConditions:
                process.env.REACT_APP_TEXT_TERMS_CONDITIONS ||
                "Terms & Conditions",
              help: process.env.REACT_APP_TEXT_HELP || "Help",
              rules: process.env.REACT_APP_TEXT_RULES || "Game Rules",
              privacyAndCookies:
                process.env.REACT_APP_TEXT_PRIVACY_POLICY || "Privacy Policy",
            },
            customDrawerLinkSlug: process.env.REACT_APP_CUSTOM_DRAWER_LINK_SLUG,
            customDrawerLinkText: process.env.REACT_APP_CUSTOM_DRAWER_LINK_TEXT,
            customExternalLinkSlug:
              process.env.REACT_APP_CUSTOM_EXTERNAL_LINK_SLUG,
            customExternalLinkText:
              process.env.REACT_APP_CUSTOM_EXTERNAL_LINK_TEXT,
            helpSlug: process.env.REACT_APP_HELP_SLUG || "help",
            privacySlug: process.env.REACT_APP_PRIVACY_SLUG,
            rulesSlug:
              process.env.REACT_APP_IS_RULES_IN_LEGAL_FOOTER === "true" &&
              process.env.REACT_APP_RULES_SLUG,
            termsConditionsFilename: process.env.REACT_APP_TERMS_SLUG,
            appTitle: process.env.REACT_APP_TITLE,
            hasSmallerFontSize: true,
            hideContactSupport:
              process.env.REACT_APP_HIDE_CONTACT_SUPPORT === "true",
            hideLow6Help: process.env.REACT_APP_HIDE_LOW6_HELP === "true",
            hideGamblingReferences:
              process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true",
          })}

        {process.env.REACT_APP_FOOTER_SHOW_DONT_SELL_INFORMATION === "true" && (
          <StyledFooterLink
            href={`https://consent-form.netlify.app/?tenant=${process.env.REACT_APP_TENANT}`}
            target="_blank"
          >
            Do Not Sell My Personal Information
          </StyledFooterLink>
        )}

        {
          (process.env.REACT_APP_HAS_SPORTSRADAR === "true" && activeBet !== null) ?
            <SportsRadarBanner /> : null
        }

        {!hideLow6Footer &&
          React.createElement(Low6Footer, {
            getPage,
            appIconUrl: createImageCdnLink(process.env.REACT_APP_MAIN_ICON),
            appVersion: "2.7.1",
            hideCtaText: false,
            ctaUrl: process.env.REACT_APP_CTA_URL,
            ctaIconUrl: process.env.REACT_APP_CTA_ICON
              ? createImageCdnLink(process.env.REACT_APP_CTA_ICON)
              : undefined,
            ctaText: process.env.REACT_APP_CTA_TEXT,
            rulesSlug:
              process.env.REACT_APP_FOOTER_USES_HELP_SLUG === "true"
                ? process.env.REACT_APP_HELP_SLUG
                : process.env.REACT_APP_RULES_SLUG,
            rulesText:
              process.env.REACT_APP_FOOTER_USES_HELP_SLUG === "true"
                ? process.env.REACT_APP_TEXT_HELP || "Help"
                : "Rules",
          })}

        {hasAffiliateLogo && process.env.REACT_APP_AFFILIATE_LOGO && (
          <AffiliateLogo />
        )}

        {hasAffiliatePopup && process.env.REACT_APP_AFFILIATE_POPUP_IMAGE && (
          <AffiliatePopup
            ctaUrl={process.env.REACT_APP_AFFILIATE_POPUP_URL}
            imageUrl={process.env.REACT_APP_AFFILIATE_POPUP_IMAGE}
            popupPosition={process.env.REACT_APP_AFFILIATE_POPUP_POSITION}
          />
        )}
      </Content>
      <Drawer />
    </Route>
  );
};

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
  getPage,
};

export default connect(mapStateToProps, mapActionsToProps)(AppRoute);
