import React from "react";
import styled from "styled-components";
import {
  Avatar,
  Skeleton,
  PenceToPounds,
  MatIcon,
} from "fiver-react-ui-components";

const StyledWrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 0;
  height: ${(p) => (p.isPageHeader ? "3rem" : "4rem")};
  background: ${(p) =>
    p.isActive
      ? p.theme.colorSuccess
      : p.isPageHeader
      ? "transparent"
      : "#fdfdfd"};

  padding: 0 0.375rem;
`;

const StyledLabel = styled.span`
  display: block;
  font-size: 0.635rem;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledValue = styled.span`
  display: block;
  height: 24px;
  line-height: 24px;
`;

const StyledRank = styled.span`
  flex: 0 0 64px;
  min-width: 64px;
  max-width: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: ${(p) => (p.isLongRank ? "1.25rem" : "1.5rem")};
  ${(p) => (p.isActive ? "color: #fff" : "")};
`;

const StyledUserName = styled.span`
  flex: 1 1 auto;
  min-width: 1px;
  margin-left: 1rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  font-size: 1rem;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(p) => (p.isActive ? "color: #fff" : "")};
`;

const StyledPrize = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.325rem;
  font-size: 1rem;
  border-radius: calc(${(p) => p.theme.borderRadius} / 2);
  background: ${(p) => p.theme.colorSuccess};
  color: ${(p) => p.theme.colorSuccessText};
`;

const StyledPrizeIcon = styled(MatIcon)`
  font-size: 14px;
`;

const StyledPrizeText = styled.span`
  margin-left: 0.325rem;
  font-size: 0.75rem;
`;

const StyledPoints = styled.span`
  flex: 0 0 64px;
  text-align: center;
  font-size: 1.5rem;
  ${(p) => (p.isActive ? "color: #fff" : "")};
`;

const Wrapper = ({
  row,
  index,
  isPageHeader = false,
  isSkeleton,
  isPreMatchLeaderboard,
  showLabels,
  onRowClicked,
}) => {
  const prize = row?.prize || row?.promoPrize;

  const convertUsername = (username) => {
    var userIndex = username.lastIndexOf("@");

    if (userIndex > 0) {
      return username.substring(0, userIndex);
    } else {
      return username;
    }
  }
  return (
    <StyledWrapper isPageHeader={isPageHeader} onClick={onRowClicked}>
      {isSkeleton ? (
        <>
          <StyledRank>
            <Skeleton skeletonWidth="22px" skeletonHeight="24px" />
          </StyledRank>
          <Skeleton isCircle skeletonWidth="34px" skeletonHeight="34px" />
          <StyledUserName>
            <Skeleton
              display="block"
              skeletonWidth={
                isPageHeader
                  ? "200px"
                  : index % 3 === 0
                  ? "200px"
                  : index % 3 === 1
                  ? "120px"
                  : index % 3 === 2
                  ? "70px"
                  : "20px"
              }
              skeletonHeight="24px"
            />
          </StyledUserName>
        </>
      ) : (
        <>
          <StyledRank isLongRank={row?.rank?.toString().length > 2}>
            {showLabels && <StyledLabel>Rank</StyledLabel>}
            <StyledValue>
              {!isPreMatchLeaderboard && row?.rank ? row.rank : "-"}
            </StyledValue>
          </StyledRank>

          <Avatar size="34px" src={row.user.image} />
          <StyledUserName>{convertUsername(row.user.username)}</StyledUserName>
          {!isPreMatchLeaderboard && prize > 0 && (
            <StyledPrize>
              <StyledPrizeIcon>emoji_events</StyledPrizeIcon>

              <StyledPrizeText>
                £<PenceToPounds>{prize}</PenceToPounds>
              </StyledPrizeText>
            </StyledPrize>
          )}
          {!isPreMatchLeaderboard && row.hasWinTieBreaker && (
            <StyledPrize>
              <StyledPrizeIcon>emoji_events</StyledPrizeIcon>
            </StyledPrize>
          )}
          {!isPreMatchLeaderboard && (
            <StyledPoints>
              {showLabels && row?.points > -1 && (
                <StyledLabel>Points</StyledLabel>
              )}
              <StyledValue>{row.points > -1 ? row.points : "-"}</StyledValue>
            </StyledPoints>
          )}
        </>
      )}
    </StyledWrapper>
  );
};

export default Wrapper;
